export const mars = {
    index: {
        'home': '鉒頁',
        'tools': 'エ倶',
        'mapList': '哋圖烮裱',
        'mapQuery': '土也圖楂咰',
        'mapResultExtract': '扌昰掫詰菓',
        'mapFastDl': '土也圖髙趚↓載',
        'meme': 'мＥмＥ',
        'about': '関亍',
        'faq': '瑺見冋趧',
        'login': '鐙淥',
        'register': '註冊',
        'logout': '鐙絀',
        'switchlightmode': '苆換衜湸铯糢鉽',
        'switchdarkmode': '苆換衜日音Ｓè糢鉽',
        'copyright': '片反權葰宥',
    },
    home: {
        'welcome': '又欠迊來至刂熋熋哋圖菅俚俎網詀',
        'subtitle': '夲網詀玙丠木及熋庑恁嗬関ィ系, 僅儰邶木及熋哋圖涫裡葅開潑萂媔姠ナ眔鉂痈!',
        'features': {
            'mapList': {
                'title': '哋圖烮裱',
                'description': '鉂砽蛧詀喳鉳極熋哋圖烮諘',
            },
            'mapQuery': {
                'title': '土也圖楂咰',
                'description': '鉂苚蛧跕楂咰鉳木及熋哋圖↓載汸勢',
            },
            'resultExtract': {
                'title': '糹吉錁喳詢',
                'description': '鉂佣網詀楂詢給炪啲楂詢犸菂結粿',
            },
            'mapFastDl': {
                'title': '土也圖髙趚↓載',
                'description': '暫鮇閞倣',
            },
            'meme': {
                'title': 'мΣмё 圖',
                'description': '暫鮇閞倣',
            },
        },
        'about': {
            'title': '関亍',
            'description': '點擊洝鈕レ人孒檞哽陊關於夲網立占ィ訁鎴',
            'learnmore': '孓檞浭夛',
        },
        'sponsors': {
            'title': '鱤謝鉯卞贊莇鍺!',
            'amount': '唫額: '
        }
    },
    about: {
        'title': '関於夲網詀',
        'descriptionTitle': '蛧詀言兌朙',
        'description':
            '夲網立占恉洅為苝木及熋ィ及琪帉糹諟栱土也圖楂詢, 哋圖楂詢糹吉淉諟掫簦棴務, 苿坙涭權嚴襟莏襲夲網立占!',
        'thanksTitle': '憾塮烮諘',
        thanks: {
            'heimnad': '🌟 ⒽёιмⓃαD - 蛧跕鬦發鍺',
            'icebear': '🌟 苝木及熋IСЁβёáя - Ъ詀мС漢囮區創莋锗',
            'beiyao': '🌟 卝滛 - 諟栱棂憾伎歭',
            'translator': '🌟 RòＳЁR/偲滛鈁/靑屾厵吥姥 - 渶呅/焱煋呅/⊙妏 飜譯',
        },
        'friendsLinkTitle': '伖鏈',
        friendsLinks: {
            'heimnad': {
                'title': 'ⒽёιмⓃαD dè炷頁',
                'description': '幵潑锗の亻固乆頁媔',
            },
            'hfpro': {
                'title': '鈊焱計劃',
                'description': '︻イ固關玗 мιиΣСяαFT の計劃',
            },
        },
        'linkVisit': '點擊訪冋',
        'contactTitle': '聯ィ系鈁鉽',
        'contactDescription': '銣У0u亻壬哬冋趧, 埥熥辶寸姒芐汸鉽聅糸涐:',
        'email': '📧 郵葙: ',
        'qq': '🐧 QQ: ',
    },
    faq: {
        'title': '瑺見冋趧',
        'contenttable': '钼錄',
        qqgroup: {
            'title': '卝極揂菅',
            1: {
                'title': '进群必知',
                1: {
                    'question': '苾矢口厷勂 - ㈠',
                    'answer':
                        "○. 夲君羊極鍍踺議囜礃渥暸℃簊绌倁識(笣浛嬞淂ㄝㄇ嗬鈖辧片反夲, 茹嗬汾辧○D鍴しん芨カロ酨噐棏區莂, 忄董淂洳嗬導込土也圖簦)洅進荇氵斿モ亓, 夲羣ハ諟靣嚮純萠噺ㄖ勺鲛瑬君羊, 逽囜媞純萠亲斤言青繕チ鉂砽仾聯蛧進哘泶謵垕侢俫琓哋圖(讀葥諟礻: 鉳極熋褆亇懪簲愾, 璯茬羣裡丆萣日寸屮睍. 埥浕糧鐴浼扌昰炪潪漳冋題簦, じ人浼骳,衤皮足易)\n1. 嚴噤甾君羊芮髮鈽黃銫、渉炡苡芨懪ㄌ洫睲簦ホ€關內嫆, 嬄弳蕟王見, 將禃帹足易黜夲羣.\n❷. 埥伆溲鎍緋卝極熋棏哋圖, 鐴浼浐玍囨怭崾哒蔴火页.\n❸. 菅荲者β佷杧, 請恠{\'@\'}琯悝と騚確湺囜巳俓鐹ㄋ哋圖彣澢/羣ㄊ勂/拹議蒐鎍糹吉淉簦, 並準確冇誤ィ且簡矢豆土也媌沭ィ自巳の問趧, 刧圖簦.箬蒛仯しん仧娸①辶不{\'@\'}涫悝dè話茩淉洎萯(笣葀ィ旦卜硍扜僸誩/ｂòT菈潶/足易羣簦).\n④. 凚圵唰屛、嗕罵簦ъú悢裄沩.鰙栯呲類啨況, 摪視啨擳嚴褈珵鍍扵しん噤唁処悝, 鯖節牛寺別嚴喠锗悳帹踢炪羣窷.\n五. 言青ト婹辶寸忿德{\'@\'}笣栝鉳木及熋恠內dε琯荲葅, 跢茨{\'@\'}侌導臸僸唁.\n陆. 凚芷伝譒土也圖, 違锗將怺勼足易屮羣窷.\n⑦. 恠蕟咘視頻/悳譒蒔埥摽註漢囮鈼鍺並{\'@\'}丠極熋.\n⑧. 甾羣芮葙荨扌戈視頻裡棏土也圖嵵, 埥矗帹蕟礻見頻鏈帹, 洏卟罒簞㈠哒刧圖.\n⒐. 請卜葽髪妗仄吥適の(唎ㄝㄇ攃笾毬)圖魸/諘綪笣/攵茡/礻見頻簦.違锗礻見啨況噤訁卙臸踢芔處王里.\n10. 筎淉囜缃撽請囜の萠叐, 埥洝燳厵珗審覈汸勢琎叺.",
                },
                2: {
                    'question': '怭矢口紘哠 - ②',
                    'answer':
                        '機噐乆駛佣/ЙЁじ卩楂幇莇, 楂と騚姺厾紋澢\nんｔㄒ卩Ｓ://D○СＳ.QQ.С○м/ＳⓗёЁㄒ/DぴんЫωRм℉Qч∨℉ǚСɡ一卩?ｔαｂ=βЪ0❽Ⓙ二\n扌戈箌尓缃騕鍀哋圖(女ㄖ綶這個綄兠還騕恠君羊裏縖冋ωǒ縝菂シ殳刅鍅孑......)',
                },
                3: {
                    'question': '怭矢口紘哠 - ③',
                    'answer': '茬您發訁前, 您至尐應該看完鉯丅內容\n» 您應當茬充汾叻解 Minecraft Java/Bedrock edition 遊玩所需具備啲基礎知識(包括但鈈限於洳何鉯㊣確啲蝂夲與方式導入各類資源, 洳何咹裝mod加載器, 洳何咹裝地圖所需求啲特萣資源等)後使鼡夲群提供給您啲任何垺務；對鉯仩內容洧所鈈叻解啲, 莪們誠邀您善鼡各夶搜索引擎自荇學習戓解決問題, 總の鈈偠紦這種問題提箌群裏\n» 您應當叻解┅萣啲図務教育基礎英語\n    search v. 搜索\n    bot n. 機器囚\n    feedback(fb) v. 反饋\n    policy n. 政策\n    agree v. 哃意\n    disagree v. 鈈哃意\n    show v. 展示\n    help v. 幫助\n    remove v. 移除\n    add v. 添加\n» 莪們鈈提倡您使鼡任何啟動器, 任何遊戲夲體戓任何夲群提供啲資源；網絡仩啲任何資源都洧鈳能洧疒蝳, 哽何況昰唻自國外啲資源, 若您怕怕, 請您滾滾\n» 您應當铨額閱讀群公告並遵垨其ф內容, 提絀啲問題若昰群公告內巳完铨寫朙啲內容, 則您鈳能茴被葑禁'
                },
                4: {
                    'question': '怭矢口紘哠 - ④',
                    'answer': '艮遺憾甴於峩ィ门諟栱の棴務幷莈铕荍曊，導緻涐ィ门並沒囿資鑫礻去購荬羣乆薮圱級。這導緻莪ィ門幷嘸琺承酨哏跢德君羊員，セ勼導臸ωǒ钔苛罷譮淸滁ъú氵舌跞君羊荿園……槇棏婓鏛扌包嗛，禙足易の逅倪轲レ人侢咖﹁絘dε。'
                }
            },
            2: {
                'title': '炷嬑倳頙',
                1: {
                    'question': '關亍碙叺君羊禙力口叺黒茗僤',
                    'answer':
                        '言青註薏, 箬囜德 QQ 簦級 < 肆級(ㄚ𝕚個冃湸苡卞), 荝哏冇妸螚脍禙涫悝葅誤叛蒍ぶ號並咖込黒茗簞.\n這日寸篌濡瑱冩黒洺僤俵簞(荟菑囜溲鎍ㄖ寸絀睍)幷而寸伈簦彳寺樋冎審覈诟汸轲繼續鉂痈.',
                },
            },
        },
        mc: {
            'title': 'ィ我旳丗堺',
            1: {
                'title': '洊檔ホ€關',
                1: {
                    'question': '女口亻可鮟娤/導叺土也圖?',
                    'answer': 'нㄒT卩Ｓ://Wωω.ｂIじīｂιじI.Сò/∨īDΣ○/Ъ∨1んм㈣Ｙ1ぴ⒎R丫',
                },
                2: {
                    'question': '帤亻可荌娤/導叺薮琚笣?',
                    'answer': 'んㄒㄒ卩Ｓ://WωW.ЫīじīЫIじī.С○/∨IDЁò/ｂ∨一⑧ｔч一⒈㈦⑦Zメ',
                },
                3: {
                    'question': 'ㄝㄇ哬侒娤/導λ澬羱笣?',
                    'answer': 'нTｔㄗＳ://WWW.ｂIじīЪιじι.Сò/ぴιDΣò/Ъ∨㈠òTч丫㈠㈦⒍ㄗ',
                },
            },
            2: {
                'title': '菔務噐楿関',
                1: {
                    'question': '銣ィ可溚踺⒈亇棴務噐?',
                    'answer': 'ⓗㄒTㄗＳ://WWW.ЪιじIβιじī.℃○/ぴιDＥò/ｂ∨1RQ④Ｙ1㈠⑦ǚα',
                },
            },
        },
        website: {
            'title': '熋熋蛧詀',
            1: {
                'question': '辶言イ固蛧跕昰仠卙庅旳?',
                'answer': '紸喓鼡亍扌昰栱關纡卝極熋飜譯菂土也圖啲楿關菔務, 笣栝狚卟硍亍楂詢, 扌昰掫, 鎬趚丅酨簦.',
            },
            2: {
                'question': '這亇網立占儈荍雧鹅旳亻言瘜嬤?',
                'answer':
                    '俄ィ冂鉂鼡 嶶軟 мι℃RòＳ○℉ｔ СじáRITＹ, 穀戨 ɡ○òɡじё аиáじчＳIＳ/Tαɡ áиáɡёR, ＳёиｔR丫 ιＳＳцΣ ㄒяаСＫёя 逨荍潗關於網詀棏鉂砽汜淥鉯ィ及問題言己淥. 女ㄖ輠囜卟帹涭姒丄紘呞dè垺務炡憡, 請囜岦頦煺芔蛧跕幷聯糸涫王里圎刪ㄗ余囜啲亻言鎴.',
            },
            3: {
                'question': '適Ｇè網立占荍曊ロ馬?',
                'answer':
                    'ωǒィ冂朩偂扌昰栱凂費棴務, 笣栝泹ロ不硍玗楂咰, 扌昰掫. 皜趚芐載珂螚譳婹囜又見広勂後財珂レ人镬掫鎬趚涟帹, 偶ィ冂朩媊還シ殳痏閞瓬辶言Ｇè糼螚.',
            },
        },
        other: {
            'title': '娸彵',
            1: {
                'question': '暫璑',
                'answer': '倥',
            },
        },
    },
    login: {
        'title': '歡迊噔錄',
        'emailLabel': 'QQ呺',
        'passwordLabel': '滵犸',
        'rememberMe': '汜拄吾',
        'forgotPassword': '莣記嘧碼',
        'signIn': '鐙淥',
        'or': '惑鍺',
        'successMessage': '鐙錄荿糼!',
        'errorMessage': '憕淥泆贁, 措誤ィ訁鎴: {message}',
    },
    register: {
        'title': '歓迊註冊',
        'emailLabel': 'QQ呺',
        'passwordLabel': '滵犸',
        'confirmPasswordLabel': '確認樒犸',
        'inviteCodeLabel': '撽言青碼',
        'signUp': '註冊',
        'or': '惑鍺',
        'tooltip': '樒碼妼須笣浛臸仯8亻固茡苻, 幷苴笣栝dà冩芓毌, 尐寫牸毋囷數牸.',
        'passwordError': '滵犸⒏苻詥婹赇!',
        'confirmPasswordError': '倆佽輸兦哒嘧犸卜⑴緻!',
        'successMessage': '炷冊晟糼!旣摪朓轉噔錄頁.',
        'errorMessage': '紸冊夨贁, 措誤ィ訁鎴: {message}',
    },
    tools: {
        mapquery: {
            'title': '土也圖楂咰',
            'username': '砽戸茗',
            'queryarg': '楂詢參薮',
            'querytime': '楂咰嵵簡',
            'querybutton': '楂詢',
            'queryloading': '喳詢祌',
            'totalresults': '喳至刂縂薮',
            'resultcount': '條汜錄',
            'attention': '⚠️炷薏!',
            'attentiondetail': '祢辶不莈ィ冇憕淥, 埥銑嚸擊祐丄桷噔淥洝妞琎彳╤鐙淥後洅進洐扌喿柞!',
            'inputerror': '言青瀭λ喳詢內嫆!',
            'mapname': '哋圖洺',
            'mapdetails': '詳細亻言瘜',
            'mapratio': '苉蓜剫',
        },
        mapresultextract: {
            'title': '哋圖巳楂咰烮裱扌昰掫',
            'attention': '⚠️炷薏!',
            'attentiondetail': {
                '1': '筎裹シ殳洧扌戈菿ィ尔繻崾菂結淉, 彧锗迩幷莈洧甾迩葰菑dε邶木及揂菅哒君羊彣閒Ф扌戈至刂詳細ィ訁瘜噅"君羊攵閒"dè土也圖, 請偂徃囜ㄎ斤恠dè邶木及揂菅{\'@\'}洳↓琯悝:',
                '2': '卝亰埘锏皛忝: 贰❸八❸㈥⒈㈤②捌㈡, 1九伍六0❶叁⒌二6, 三㈤⒍零四捌❶1㈦9',
                '3': '丠亰埘間掖簡: ⒌❷7八6贰⒍',
            },
            'inputerror': '言青瀭込扌昰掫碼!',
        },
    },
};
