import { createI18n } from 'vue-i18n'
import { zhCN } from './locale/zh'
import { enUS } from './locale/en'
import { jaJP } from './locale/jp'
import { mars } from './locale/mars'

const messages = {
    'zh_CN': { ...zhCN },
    'en_US': { ...enUS },
    'ja_JP': { ...jaJP },
    'mars': { ...mars }
}

const lang = localStorage.getItem('lang') || 'zh_CN'

// 这里不需要 locale.use，直接在创建 i18n 实例时指定 locale
const i18n = createI18n({
    legacy: false,
    locale: lang,
    messages: messages
})

export default i18n