<template>
  <div :class="{ 'dark': isDarkMode }"
    class="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col dark-mode-transition" ref="appContainer">
    <!-- 导航栏 -->
    <nav class="bg-white dark:bg-gray-800 shadow-md fixed top-0 left-0 right-0 z-10 dark-mode-transition">
      <div class="container mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <!-- Logo 和导航链接 -->
          <div class="flex items-center">
            <router-link to="/" class="flex-shrink-0">
              <img class="h-12 w-12 rounded-full" src="/logo.jpg" alt="Logo">
            </router-link>

            <!-- 桌面端导航菜单 -->
            <div class="hidden lg:block">
              <div class="ml-10 flex items-center space-x-4">
                <!-- 主页按钮 -->
                <router-link to="/"
                  class="text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium flex items-center noselect">
                  <HomeIcon class="h-5 w-5 mr-2" />
                  {{ t('index.home') }}
                </router-link>

                <!-- 工具按钮 -->
                <div class="relative" @mouseenter="showTools = true" @mouseleave="showTools = false">
                  <button
                    class="text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium flex items-center noselect">
                    <WrenchIcon class="h-5 w-5 mr-2" />
                    {{ t('index.tools') }}
                    <ChevronRightIcon class="h-4 w-4 ml-1 transition-transform duration-300"
                      :class="{ 'rotate-90': showTools }" />
                  </button>

                  <!-- 工具下拉菜单 -->
                  <transition enter-active-class="transition ease-out duration-200"
                    enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0"
                    leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0"
                    leave-to-class="opacity-0 translate-y-1">
                    <div v-show="showTools"
                      class="absolute left-0 mt-2 w-48 rounded-lg shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5">
                      <div>
                        <div class="hover:bg-gray-200 dark:hover:bg-gray-700 flex items-center">
                          <DocumentTextIcon class="h-5 w-5 ml-2 text-gray-700 dark:text-gray-200" />
                          <a href="/tools/maplist"
                            class="block px-2 py-2 text-sm text-gray-700 dark:text-gray-200 hover:text-gray-900 dark:hover:text-white">
                            {{ t('index.mapList') }}
                          </a>
                        </div>
                        <div class="hover:bg-gray-200 dark:hover:bg-gray-700 flex items-center">
                          <MagnifyingGlassIcon class="h-5 w-5 ml-2 text-gray-700 dark:text-gray-200" />
                          <a href="/tools/mapquery"
                            class="block px-2 py-2 text-sm text-gray-700 dark:text-gray-200 hover:text-gray-900 dark:hover:text-white">{{
                              t('index.mapQuery') }}</a>
                        </div>
                        <div class="hover:bg-gray-200 dark:hover:bg-gray-700 flex items-center">
                          <PaperClipIcon class="h-5 w-5 ml-2 text-gray-700 dark:text-gray-200" />
                          <a href="/tools/mapresultextract"
                            class="block px-2 py-2 text-sm text-gray-700 dark:text-gray-200 hover:text-gray-900 dark:hover:text-white">{{
                              t('index.mapResultExtract') }}</a>
                        </div>
                        <div class="hover:bg-gray-200 dark:hover:bg-gray-700 flex items-center">
                          <ArrowDownTrayIcon class="h-5 w-5 ml-2 text-gray-700 dark:text-gray-200" />
                          <a href="#"
                            class="block px-2 py-2 text-sm text-gray-700 dark:text-gray-200 hover:text-gray-900 dark:hover:text-white">{{
                              t('index.mapFastDl') }}</a>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>

                <!-- meme按钮 -->
                <router-link to="/meme"
                  class="text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium flex items-center noselect">
                  <FaceSmileIcon class="h-5 w-5 mr-2" />
                  {{ t('index.meme') }}
                </router-link>

                <!-- FAQ 按钮 -->
                <router-link to="/faq"
                  class="text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium flex items-center noselect">
                  <QuestionMarkCircleIcon class="h-5 w-5 mr-2" />
                  {{ t('index.faq') }}
                </router-link>

                <!-- 关于按钮 -->
                <router-link to="/about"
                  class="text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium flex items-center noselect">
                  <InformationCircleIcon class="h-5 w-5 mr-2" />
                  {{ t('index.about') }}
                </router-link>
              </div>
            </div>
          </div>

          <!-- 右侧元素：语言选择、登录和注册 -->
          <div class="hidden lg:flex items-center space-x-4">
            <!-- 语言切换下拉框 -->
            <div class="relative inline-block text-left">
              <select v-model="currentLang" @change="changeLanguage"
                class="border border-gray-300 dark:border-gray-600 rounded-md p-2 text-sm bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-200 appearance-none pr-10">
                <option value="zh_CN">中文</option>
                <option value="en_US">English</option>
                <option value="ja_JP">日本語</option>
                <option value="mars">钬暒妏</option>
              </select>
              <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <LanguageIcon class="h-5 w-5 ml-2 text-gray-700 dark:text-gray-200" />
              </span>
            </div>

            <!-- 暗色模式切换按钮 -->
            <button @click="toggleDarkMode"
              class="ml-4 p-2 rounded-md text-gray-700 dark:text-gray-200 bg-gray-200 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600">
              <SunIcon v-if="isDarkMode" class="h-5 w-5" />
              <MoonIcon v-else class="h-5 w-5" />
            </button>

            <!-- 登录状态判断 -->
            <div v-if="isLoggedIn">
              <span class="text-gray-700 dark:text-gray-200">{{ username }}</span>
              <button @click="logout"
                class="bg-red-600 text-white hover:bg-red-700 px-4 py-2 rounded-md text-sm font-medium ml-3">
                {{ t('index.logout') }}
              </button>
            </div>
            <div v-else>
              <router-link to="/login"
                class="text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                {{ t('index.login') }}
              </router-link>
              <router-link to="/register"
                class="bg-indigo-600 text-white hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium ml-3">
                {{ t('index.register') }}
              </router-link>
            </div>
          </div>

          <!-- 移动端菜单按钮 -->
          <div class="-mr-2 flex lg:hidden">
            <button @click="toggleMenu" type="button"
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span class="sr-only">Open main menu</span>
              <Bars3Icon v-if="!isOpen" class="block h-6 w-6" />
              <XMarkIcon v-else class="block h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
    </nav>

    <!-- 移动端菜单内容 -->
    <div class="md:hidden fixed top-16 left-0 right-0 bg-white dark:bg-gray-800 shadow-md z-50 dark-mode-transition"
      id="mobile-menu" v-show="isOpen">
      <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <!-- 切换亮色/暗色模式按钮 -->
        <button @click="toggleDarkMode"
          class="flex items-center text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white px-3 py-2 rounded-md text-base font-medium">
          <span>
            <SunIcon v-if="isDarkMode" class="h-6 w-6 mr-3" />
            <MoonIcon v-else class="h-6 w-6 mr-3" />
          </span>
          <div v-if="isDarkMode">
            {{ t('index.switchlightmode') }}
          </div>
          <div v-else>
            {{ t('index.switchdarkmode') }}
          </div>
        </button>

        <router-link @click="closeMenu" to="/"
          class="text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium">
          <div class="flex items-center">
            <HomeIcon class="h-6 w-6 mr-3" />
            {{ t('index.home') }}
          </div>
        </router-link>

        <!-- 工具菜单 -->
        <div @click="mobileShowTools = !mobileShowTools"
          class="text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium">
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <WrenchIcon class="h-6 w-6 mr-3" />
              {{ t('index.tools') }}
            </div>
            <ChevronRightIcon class="h-5 w-5 transition-transform duration-300"
              :class="{ 'rotate-90': mobileShowTools }" />
          </div>
        </div>

        <!-- 工具子菜单 -->
        <div v-show="mobileShowTools" class="pl-12">
          <div class="flex items-center" @click="closeMenu">
            <DocumentTextIcon class="h-5 w-5 mr-2 dark:text-gray-200" />
            <a href="/tools/maplist"
              class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-200 hover:text-gray-900 dark:hover:text-white">{{
                t('index.mapList') }}</a>
          </div>
          <div class="flex items-center" @click="closeMenu">
            <MagnifyingGlassIcon class="h-5 w-5 mr-2 dark:text-gray-200" />
            <a href="/tools/mapquery"
              class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-200 hover:text-gray-900 dark:hover:text-white">{{
                t('index.mapQuery') }}</a>
          </div>
          <div class="flex items-center" @click="closeMenu">
            <PaperClipIcon class="h-5 w-5 mr-2 dark:text-gray-200" />
            <a href="/tools/mapresultextract"
              class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-200 hover:text-gray-900 dark:hover:text-white">{{
                t('index.mapResultExtract') }}</a>
          </div>
          <div class="flex items-center" @click="closeMenu">
            <ArrowDownTrayIcon class="h-5 w-5 mr-2 dark:text-gray-200" />
            <a href="#"
              class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-200 hover:text-gray-900 dark:hover:text-white">{{
                t('index.mapFastDl') }}</a>
          </div>
        </div>

        <!-- meme -->
        <router-link @click="closeMenu" to="/meme"
          class="text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium">
          <div class="flex items-center">
            <FaceSmileIcon class="h-6 w-6 mr-3" />
            {{ t('index.meme') }}
          </div>
        </router-link>

        <!-- FAQ -->
        <router-link @click="closeMenu" to="/faq"
          class="text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium">
          <div class="flex items-center">
            <QuestionMarkCircleIcon class="h-6 w-6 mr-3" />
            {{ t('index.faq') }}
          </div>
        </router-link>

        <!-- 关于链接 -->
        <router-link @click="closeMenu" to="/about"
          class="text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium">
          <div class="flex items-center">
            <InformationCircleIcon class="h-6 w-6 mr-3" />
            {{ t('index.about') }}
          </div>
        </router-link>
      </div>

      <!-- 移动端的登录和注册按钮 -->
      <div class="px-4 py-4 space-y-3 border-t border-gray-200 dark:border-gray-600">
        <div v-if="isLoggedIn">
          <span class="block w-full text-gray-700 dark:text-gray-200 font-medium text-center">{{ username }}</span>
          <button @click="logout"
            class="block w-full px-4 py-3 text-white bg-red-600 rounded-lg font-medium hover:bg-red-700 transition-colors duration-200 text-center">
            {{ t('index.logout') }}
          </button>
        </div>
        <div v-else>
          <router-link @click="closeMenu" to="/login"
            class="block w-full px-4 py-3 text-gray-700 bg-white border border-gray-300 rounded-lg font-medium hover:bg-gray-50 transition-colors duration-200 text-center">
            {{ t('index.login') }}
          </router-link>
          <br>
          <router-link @click="closeMenu" to="/register"
            class="block w-full px-4 py-3 text-white bg-indigo-600 rounded-lg font-medium hover:bg-indigo-700 transition-colors duration-200 text-center">
            {{ t('index.register') }}
          </router-link>
        </div>
      </div>

      <!-- 移动端语言选择 -->
      <div class="px-2 pt-2 pb-3 border-t border-gray-200 dark:border-gray-600">
        <div class="relative">
          <select v-model="currentLang" @change="changeLanguage"
            class="mt-1 block w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md py-2 pl-10 pr-10 text-sm text-gray-700 dark:text-gray-200 appearance-none">
            <option value="zh_CN">中文</option>
            <option value="en_US">English</option>
            <option value="ja_JP">日本語</option>
            <option value="mars">钬暒妏</option>
          </select>
          <span class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <LanguageIcon class="w-5 h-5 text-gray-400 dark:text-gray-200" />
          </span>
        </div>
      </div>
    </div>

    <!-- 主要内容区域 -->
    <main class="flex-grow bg-gray-100 dark:bg-gray-900 dark-mode-transition">
      <div class="router-view-container relative flex-grow">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in" @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter"
            @before-leave="beforeLeave" @leave="leave" @after-leave="afterLeave">
            <component :is="Component" :key="$route.fullPath" class="w-full" />
          </transition>
        </router-view>
      </div>
    </main>

    <!-- Footer -->
    <footer
      class="bg-white dark:bg-gray-800 shadow-md text-gray-700 dark:text-gray-200 text-center py-4 dark-mode-transition">
      <p>&copy; {{ new Date().getFullYear() }} {{ $t('index.copyright') }} HeimNad</p>
    </footer>

    <!-- 返回顶部按钮 -->
    <button v-show="showBackToTop" @click="scrollToTop"
      class="fixed bottom-16 right-4 bg-gray-800 dark:bg-gray-700 text-white p-2 rounded-full shadow-lg hover:bg-gray-700 dark:hover:bg-gray-600 transition-colors duration-300">
      <ArrowUpIcon class="h-6 w-6" />
    </button>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted, nextTick } from 'vue'
import {
  HomeIcon, WrenchIcon, ChevronRightIcon, DocumentTextIcon, MagnifyingGlassIcon, PaperClipIcon, FaceSmileIcon,
  ArrowDownTrayIcon, InformationCircleIcon, Bars3Icon, XMarkIcon, ArrowUpIcon,
  SunIcon, MoonIcon,
  LanguageIcon,
  QuestionMarkCircleIcon
} from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'
import axios from 'axios'

const { t, locale } = useI18n()

// 状态变量
const showTools = ref(false)
const mobileShowTools = ref(false)
const currentLang = ref(locale.value)
const isOpen = ref(false)
const showBackToTop = ref(false)
const isLoggedIn = ref(false)
const username = ref('')
const isDarkMode = ref(false);

// 判断环境
const isProduction = import.meta.env.VITE_MODE === 'production'
const baseURL = isProduction ? import.meta.env.VITE_APP_API_URL : import.meta.env.VITE_APP_DEV_API_URL

// 水印相关
const appContainer = ref(null)
const watermarkContent = ref('HeimNad Copyright')

// 切换菜单打开状态
const toggleMenu = () => {
  isOpen.value = !isOpen.value;
}

const beforeEnter = (el) => {
  el.style.opacity = '0';
}

const enter = (el) => {
  el.style.transition = 'opacity 0.3s ease';
  nextTick(() => {
    el.style.opacity = '1';
  });
}

const afterEnter = (el) => {
  el.style.transition = '';
}

const beforeLeave = (el) => {
  el.style.opacity = '1';
}

const leave = (el) => {
  el.style.transition = 'opacity 0.3s ease';
  el.style.opacity = '0';
}

const afterLeave = (el) => {
  el.style.transition = '';
}

// 暗色模式切换函数
const toggleDarkMode = () => {
  isDarkMode.value = !isDarkMode.value;
  localStorage.setItem('theme', isDarkMode.value ? 'dark' : 'light');
  if (isDarkMode.value) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
};

// 检查用户的本地时间并设置主题
const setThemeBasedOnTime = () => {
  const hour = new Date().getHours();
  // 假设晚上 7 点到早上 7 点为暗色模式
  isDarkMode.value = hour >= 19 || hour < 7;
  localStorage.setItem('theme', isDarkMode.value ? 'dark' : 'light');
  document.documentElement.classList.toggle('dark', isDarkMode.value);
}

// 监听语言变化
watch(currentLang, (newLang) => {
  locale.value = newLang
  localStorage.setItem('lang', newLang)
});

// 初始化语言
const savedLang = localStorage.getItem('lang');
if (savedLang) {
  currentLang.value = savedLang;
  locale.value = savedLang;
}

// 退出登录
const logout = () => {
  isLoggedIn.value = false;
  username.value = '';
  localStorage.removeItem('token');
  closeMenu();
}

// 获取用户信息
const getUserInfo = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      const response = await axios.get(`${baseURL}/auth/user`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      username.value = response.data.username;
      isLoggedIn.value = true;
    } catch (error) {
      console.log("获取用户信息失败:", error);
      if (error.response) {
        console.log("错误信息:", error.response.data.msg);
      } else {
        console.error("请求失败:", error.message);
      }
    }
  } else {
    console.log("没有找到有效的 token");
  }
}

// 关闭菜单
const closeMenu = () => {
  isOpen.value = false
  mobileShowTools.value = false
}

// 滚动到顶部
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

// 检查滚动位置并更新返回顶部按钮显示状态
const checkScroll = () => {
  showBackToTop.value = window.pageYOffset > 300
}

// 检测调试器是否存在
const isDebuggerPresent = () => {
  return !!window.console && (console.firebug || (console.exception && console.table)) ||
    (console && console.debug) || (window.navigator.userAgent.includes('Chrome') && window.console);
};

// 进行调试器检测
const debuggerDetection = () => {
  if (isProduction) {
    setInterval(() => {
      if (isDebuggerPresent()) {
        debugger;
      }
    }, 1000);
  }
};

// 生成SVG水印
const generateSvgWatermark = (text) => {
  const timestamp = new Date().toLocaleString()
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="100">
      <text 
        x="50%" 
        y="40%" 
        font-family="Arial" 
        font-size="14" 
        fill="rgba(200, 200, 200, 0.3)" 
        text-anchor="middle" 
        transform="rotate(-30 100 50)"
      >${text}</text>
      <text 
        x="50%" 
        y="60%" 
        font-family="Arial" 
        font-size="12" 
        fill="rgba(200, 200, 200, 0.3)" 
        text-anchor="middle" 
        transform="rotate(-30 100 50)"
      >${timestamp}</text>
    </svg>
  `
  return `url("data:image/svg+xml;charset=utf8,${encodeURIComponent(svg)}")`
}

// 创建单个水印元素
const createWatermarkElement = (text) => {
  const el = document.createElement('div')
  el.className = 'watermark-layer'
  el.style.position = 'fixed'
  el.style.top = '0'
  el.style.left = '0'
  el.style.width = '100%'
  el.style.height = '100%'
  el.style.pointerEvents = 'none'
  el.style.userSelect = 'none'
  el.style.zIndex = '9999'
  el.style.background = generateSvgWatermark(text)
  // 设置水印的平铺属性
  el.style.backgroundRepeat = 'repeat'
  el.style.backgroundPosition = 'center'
  el.style.backgroundSize = '200px 100px'
  return el
}

// 创建水印
const createWatermark = () => {
  const container = appContainer.value
  if (!container) return

  // 清除现有水印
  container.querySelectorAll('.watermark-layer').forEach(el => el.remove())

  // 创建新水印
  const watermarkEl = createWatermarkElement(watermarkContent.value)
  container.appendChild(watermarkEl)
}

// 检查并恢复水印
const checkAndRestoreWatermark = () => {
  const container = appContainer.value
  if (!container) return

  const watermarks = container.querySelectorAll('.watermark-layer')
  if (watermarks.length === 0) {
    createWatermark()
  }
}

// 观察DOM变化
const observeDOM = () => {
  const observer = new MutationObserver((mutations) => {
    let needCheck = false
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList' || mutation.type === 'attributes') {
        needCheck = true
      }
    })
    if (needCheck) {
      checkAndRestoreWatermark()
    }
  })

  observer.observe(appContainer.value, {
    childList: true,
    subtree: true,
    attributes: true,
    characterData: true
  })
}

// 窗口调整大小处理程序
const handleResize = () => {
  createWatermark()
}

// 组件挂载钩子
onMounted(() => {
  debuggerDetection();
  createWatermark();
  observeDOM();
  getUserInfo();
  window.addEventListener('resize', handleResize);

  // 定期更新水印
  setInterval(() => {
    createWatermark();
  }, 30000); // 每30秒更新一次

  // 获取系统颜色模式偏好
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

  // 获取本地存储的主题设置
  const savedTheme = localStorage.getItem('theme');

  // 根据本地存储或系统偏好设置主题
  if (savedTheme) {
    isDarkMode.value = savedTheme === 'dark';
  } else {
    // 如果没有保存的主题设置，使用时间设置
    setThemeBasedOnTime();
  }

  // 更新文档的主题类
  document.documentElement.classList.toggle('dark', isDarkMode.value);
});

// 组件卸载钩子
onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* 扩展现有的深色模式过渡 */
.dark-mode-transition {
  transition: background-color 0.25s ease,
    color 0.25s ease,
    border-color 0.25s ease,
    box-shadow 0.25s ease,
    opacity 0.25s ease,
    transform 0.25s ease;
}

.noselect {
  user-select: none;
}

.rotate-90 {
  transform: rotate(90deg);
}

/* 保持现有的深色模式过渡 */
.dark-mode-transition {
  transition: background-color 0.25s ease,
    color 0.25s ease,
    border-color 0.25s ease,
    box-shadow 0.25s ease;
}

/* 确保所有文本颜色变化也有过渡效果 */
.dark-mode-transition * {
  transition: color 0.25s ease,
    background-color 0.25s ease,
    border-color 0.25s ease;
}

#mobile-menu {
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
}

/* 调整返回顶部按钮的位置 */
button.fixed.bottom-16 {
  bottom: 5rem;
}
</style>